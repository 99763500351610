import store from "../store/index";

let togglePdf = (filePath) => {
  if (store.state.pdfIsOpen) {
    store.state.pdfIsOpen = false;
  } else {
    store.state.currentPdf = filePath;
    store.state.pdfIsOpen = true;
  }
};

export default { togglePdf };
