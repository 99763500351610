import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import scenes from "./modules/scenes";
import bios from "./modules/bios";

export default new Vuex.Store({
  state: {
    devMode: true,
    // SCENES DATAS
    defaultScene: 0,
    currentScene: { name: null, sceneId: null, id: null },

    clickPos: [
      { // X
        deg: 0,
        rad: 0,
      },
      { // Y
        deg: 0,
        rad: 0,
      },
    ],

    time: 0,

    clickX: 0,
    clickY: 0,

    userBioIsOpen: false,
    guestBookIsOpen: false,

    videoPlayerIsOpen: false,
    currentVideo: null,

    nextRoom: null,
    nextRoomParameters: null,

    //PDF DATAS
    currentPdf: null,
    pdfIsOpen: false,
    pdfDemoFile: 'demo.pdf'

  },
  mutations: {
    UPDATE_CLICKPOS(state, data) {
      state.clickPos = data;
    },
  },
  actions: {},
  modules: {
    scenes,
    bios,
  },
});
