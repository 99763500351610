<template>
<div id="bio-main">
  <div id="bio-main-container">
  <div @click="$store.state.guestBookIsOpen = false" class="close-button"> <i class="mdi mdi-close"></i> </div>
    <div class="users guest-book">
      <iframe src="https://guestbook.cci-summit.com/" width="100%" height="100%" frameborder="0"></iframe>
    </div>
  </div>
</div>
</template>

<script>

export default {
  name: "GuestBook",
};
</script>

<style lang="scss" scoped>
@import "bio";
</style>