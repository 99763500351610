<template>
  <div>
    <a href="javascript:void(0)" id="fullscreenToggle">
      <img class="icon off" src="img/fullscreen.png" />
      <img class="icon on" src="img/windowed.png" />
    </a>
    <div id="nav-right-top">
      <a class="summit-webcast" @click.prevent="switchScene(8)" target="_blank" id="goMetting">Watch Summit Recordings</a>
      <a href="javascript:void(0)" id="goHome">
        <i class="home-btn mdi mdi-home"></i>
      </a>
    </div>
    <div id="nav-left-bottom">
      <a id="tutorial" @click.prevent="openTuto">
        Tutorial
      </a>
    </div>
    <div id="nav-bottom-center">
      <a
        href="javascript:void(0)"
        id="viewUp"
        class="viewControlButton viewControlButton-1"
      >
        <i class="mdi mdi-chevron-up"></i>
      </a>
      <a
        href="javascript:void(0)"
        id="viewDown"
        class="viewControlButton viewControlButton-2"
      >
        <i class="mdi mdi-chevron-down"></i>
      </a>
      <a
        href="javascript:void(0)"
        id="viewLeft"
        class="viewControlButton viewControlButton-3"
      >
        <i class="mdi mdi-chevron-left"></i>
      </a>
      <a
        href="javascript:void(0)"
        id="viewRight"
        class="viewControlButton viewControlButton-4"
      >
        <i class="mdi mdi-chevron-right"></i>
      </a>
      <a
        href="javascript:void(0)"
        id="viewIn"
        class="viewControlButton viewControlButton-5"
      >
        <i class="mdi mdi-plus"></i>
      </a>
      <a
        href="javascript:void(0)"
        id="viewOut"
        class="viewControlButton viewControlButton-6"
      >
        <i class="mdi mdi-minus"></i>
      </a>
    </div>
    <div id="logo-bottom-right">
      <div><img src="@/assets/novartis_logo_white.png" alt="logo_novartis"></div>
    </div>
    <div id="main_nav" class="open">
      <a href="javascript:void(0)" id="main_nav_toggle">
        <i class="mdi mdi-menu"> <span>MENU</span></i>
        <i class="mdi mdi-close"></i>
      </a>
      <div class="main-nav-container title">
        <h1>Co-Creating Impact Summit</h1>
      </div>
      <div class="main-nav container">
        <div id="main_menu">
          <div class="special-links">
            <div class="summit-webcast">
              <!-- @click="switchScene(8)" -->
              <a @click.prevent="switchScene(8)" target="_blank">Watch Summit Recordings</a>
            </div>
          </div>
          <h2>Exhibition Areas</h2>
          <div class="links">

            <div>
              <a href="javascript:void(0)" @click="switchScene(3)"
                ><i class="mdi mdi-numeric-1-circle"></i>Awareness and context</a
              >
            </div>
            <div>
              <a href="javascript:void(0)" @click="switchScene(17)"
                ><i class="mdi mdi-numeric-2-circle"></i>Science and methodology</a
              >
            </div>
            <div>
              <a href="javascript:void(0)" @click="switchScene(4)"><i class="mdi mdi-numeric-3-circle"></i>Capabilities</a>
            </div>
            <div>
              <a href="javascript:void(0)" @click="switchScene(5)"><i class="mdi mdi-numeric-4-circle"></i>Standards</a>
            </div>
            <div>
              <a href="javascript:void(0)" @click="switchScene(13)"
                ><i class="mdi mdi-numeric-5-circle"></i>Practitioners corner</a
              >
            </div>
            <div>
              <a href="javascript:void(0)" @click="switchScene(15)"
                ><i class="mdi mdi-numeric-6-circle"></i>External trends</a
              >
            </div>

          </div>
        </div>
        <div id="secondary_menu">
          <div class="links">
<!--            <div>
              <a href="https://ivacguestbook.com/" target="_blank"><i class="mdi mdi-book-open-page-variant-outline"></i>Guest Book</a>
            </div>-->
            <div>
              <a @click.prevent="$store.state.guestBookIsOpen = true"><i class="mdi mdi-book-open-page-variant-outline"></i>Guest Book</a>
            </div>
            <div>
              <a @click="togglePdf('Agenda_Co-Creating Impact Summit 2021_v25Nov2021.pdf')"
                ><i class="mdi mdi-calendar"></i>Agenda</a
              >
            </div>
            <div>
              <a @click="$store.state.userBioIsOpen = true"
              ><i class="mdi mdi-account"></i>Speakers Bios</a
              >
            </div>
            <div>
              <a @click="togglePdf('CCI Catalogue for Virtual Exhibition 2021.pdf')"
              ><i class="mdi mdi-note-multiple"></i>Virtual Exhibition Catalogue</a
              >
            </div>
            <div>
              <a href="mailto:creating.impact@mci-group.com?cc=creating.impact@novartis.com&subject=Helpdesk ivac 2021"><i class="mdi mdi-help-circle"></i>Helpdesk</a>
            </div>
          </div>
        </div>
        <div class="logo-novartis">
          <img src="@/assets/novartis_logo_pos_rgb.gif" alt="logo-novartis" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var Marzipano = require("marzipano");
import eventStore from "@/services/eventStore";
import time from "@/services/time"
export default {
  computed: {
    data() {
      return this.$store.state;
    },
  },
  data() {
    return {
      meetingLink: "",
      meetingLinkContent: "Register for the Summit",
      meetingLinksList: {
        register: "https://web.cvent.com/event/a33793ee-f4cb-4e0d-b13c-b44b0b9cf571/summary",
        matin: "https://novartis.webcasts.com/starthere.jsp?ei=1512650&tp_key=14695dd100",
        apresmidi: "https://novartis.webcasts.com/starthere.jsp?ei=1512657&tp_key=b79447f73e",
      }
    };
  },
  props: {
    viewer: {
      type: Object,
    },
  },
  mounted() {
    this.init();
    this.startTimer();
  },
  methods: {
    init() {

      var viewUpElement = viewUp;
      var viewDownElement = viewDown;
      var viewLeftElement = viewLeft;
      var viewRightElement = viewRight;
      var viewInElement = viewIn;
      var viewOutElement = viewOut;
      var btnToggle = main_nav_toggle;
      var mainNav = main_nav;

      var goHomeEl = goHome;

      // Dynamic parameters for controls.
      var velocity = 0.5;
      var friction = 3;

      // Associate view controls with elements.
      var viewer = this.viewer;
      var controls = viewer.controls();
      var data = this.data;

      controls.registerMethod(
        "upElement",
        new Marzipano.ElementPressControlMethod(
          viewUpElement,
          "y",
          -velocity,
          friction
        ),
        true
      );
      controls.registerMethod(
        "downElement",
        new Marzipano.ElementPressControlMethod(
          viewDownElement,
          "y",
          velocity,
          friction
        ),
        true
      );
      controls.registerMethod(
        "leftElement",
        new Marzipano.ElementPressControlMethod(
          viewLeftElement,
          "x",
          -velocity,
          friction
        ),
        true
      );
      controls.registerMethod(
        "rightElement",
        new Marzipano.ElementPressControlMethod(
          viewRightElement,
          "x",
          velocity,
          friction
        ),
        true
      );
      controls.registerMethod(
        "inElement",
        new Marzipano.ElementPressControlMethod(
          viewInElement,
          "zoom",
          -velocity,
          friction
        ),
        true
      );
      controls.registerMethod(
        "outElement",
        new Marzipano.ElementPressControlMethod(
          viewOutElement,
          "zoom",
          velocity,
          friction
        ),
        true
      );

      goHomeEl.addEventListener("click", () => {
        viewer.switchScene(viewer._scenes[2], {
          transitionDuration: 1000,
        });
        data.currentScene = "";
      });

      btnToggle.addEventListener("click", () => {
        mainNav.classList.toggle("open");
      });


      this.addScrollBarIfToSmall();
      let timer = true;
      window.addEventListener("resize", () => {
        if (timer) {
          timer = false;
          setTimeout(() => {
            this.addScrollBarIfToSmall();
            timer = true;
          }, 500);
        }
      });

    },
    togglePdf(file) {
      this.closeMenu();
      eventStore.togglePdf(file);
    },
    closeMenu() {
      document.querySelector("#main_nav").classList.toggle("open");
    },
    addScrollBarIfToSmall() {
      let mNav = document.querySelector(".main-nav.container");
      let title = document.querySelector(".main-nav-container.title");
      if (mNav.clientHeight + title.clientHeight + 10 > window.innerHeight) {
        mNav.classList.add("small-screen");
      } else {
        mNav.classList.remove("small-screen");
      }
    },
    openTuto(){
      this.$store.state.videoPlayerIsOpen = true;
      this.$store.state.currentVideo = "https://player.vimeo.com/video/643840870?h=4e20a70568"
    },
    switchScene(sceneID){
      var viewer = this.viewer;
      viewer.switchScene(viewer._scenes[sceneID], {
        transitionDuration: 1000,
      });
      //data.currentScene = "";
    },
    startTimer(){
      let currentTime = time.getTime(1)
      console.log(currentTime);

      var myDateMeetingMatin = new Date(Date.UTC('2021','11','2','4','0','0'))
      //myDateMeetingMatin = new Date(Date.UTC('2021','10','29','8','47','0'))
      let myDateMeetingMatinToTS = myDateMeetingMatin.getTime()
      var myDateMeetingApresMidi = new Date(Date.UTC('2021','11','2','11','30','0'))
      //myDateMeetingApresMidi = new Date(Date.UTC('2021','10','29','8','49','0'))
      let myDateMeetingApresMidiToTS = myDateMeetingApresMidi.getTime()

      console.log(myDateMeetingMatin)
      console.log(Math.round((myDateMeetingMatinToTS - currentTime.timestamp) / (1000 * 60 * 60) * 100) / 100 )
      console.log(myDateMeetingApresMidi)
      console.log(Math.round((myDateMeetingApresMidiToTS - currentTime.timestamp) / (1000 * 60 * 60) * 100) / 100 )


      if(myDateMeetingMatinToTS < currentTime.timestamp){
        if (myDateMeetingApresMidiToTS < currentTime.timestamp) {
          if (this.meetingLink !== this.meetingLinksList.apresmidi) {
            this.meetingLink = this.meetingLinksList.apresmidi
            this.meetingLinkContent = "Join the Summit Meeting"
          }
        } else if (this.meetingLink !== this.meetingLinksList.matin){
          this.meetingLink = this.meetingLinksList.matin
          this.meetingLinkContent = "Join the Summit Meeting"
        }
      } else {
        if (this.meetingLink !== this.meetingLinksList.register) {
          this.meetingLink = this.meetingLinksList.register
          this.meetingLinkContent = "Register for the Summit"
        }
      }
      setTimeout(() => {
        this.startTimer()
      }, 10000)
    }

  },
};
function degToRad(value) {
  return (value * Math.PI) / 180;
}
</script>

<style lang="scss">
@import "Navigation";
</style>
