<template>
  <div :class="{ hidden: !pdfIsOpen }" id="document-viewer">
    <a class="btn-close" v-if="pdfIsOpen" @click.prevent="closeViewer"
      ><img src="/img/close.png" alt="" /><span> Close</span></a
    >
    <div class="pdf-canvas-container">
      <pdf-reader v-if="devMode" :path="`${path}`" :fileName="`../../../pdf/${pdf}#page=1`" />
      <pdf-reader v-else :path="`${path}`" :fileName="`../../../pdf/${pdf}`"/>
    </div>
  </div>
</template>

<script>
//import pdf from 'vue-pdf'
import PdfReader from "@/components/pdf/PdfReader.vue";
import { mapState } from 'vuex';

export default {
  components: {
    PdfReader,
  },
  props: {
    pdf: {
      type: String,
    },
  },
  data() {
    return {
      path: "./pdfjs/web/viewer.html",
    };
  },
  computed: {
    ...mapState(['pdfIsOpen', 'devMode', 'clickX', 'clickY'])
  },
  methods: {
    closeViewer() {
      this.$store.state.pdfIsOpen = false;
    },
  },
};
</script>

<style lang="scss">
@import "DocumentViewer.scss";
</style>
