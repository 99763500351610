export default {
  state: {
    scenes: [
      {
        id: "ext00",
        name: "Outdoor",
        initialViewParameters: {
          yaw: degToRad(-16),
          pitch: degToRad(-20),
          fov: degToRad(85),
        },
        linkHotspots: [
          {
            yaw: degToRad(-21.40),
            pitch: degToRad(-0.35),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "ext01",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(19.96),
                pitch: degToRad(0.67),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
        ],
        /*
        iframeVideosHotspots:[
          {
            yaw: degToRad(41.99),
            pitch: degToRad(-14.01),
            "width": 700,
            "height": 394,
            "perspective": 1250,
            "rotation": {
              "x": 15,
              "y": 0,
              "z": 0,
            },
            'videosData':[
              {
                "name": 'VBA Introduction',
                "url": 'https://www.youtube.com/embed/BcDhiED-vHM',
                //https://youtu.be/BcDhiED-vHM
              },
              {
                "name": 'SK Group Chair Tae won Chey',
                "url": 'https://www.youtube.com/embed/0TJJF_LbpVo',
                //https://youtu.be/0TJJF_LbpVo
              },
              {
                "name": 'SASAC Chairman Hao Peng',
                "url": 'https://www.youtube.com/embed/g5klwCIn0Lo',
                //https://youtu.be/g5klwCIn0Lo
              },
              {
                "name": 'BASF Saori Dubourg',
                "url": 'https://www.youtube.com/embed/RVX3yN-4lAQ',
                //https://youtu.be/RVX3yN-4lAQ
              },
              {
                "name": 'Deutche Bank Berthold Fürst',
                "url": 'https://www.youtube.com/embed/7DxGxX41Q-8',
                //https://youtu.be/7DxGxX41Q-8
              },
              {
                "name": 'Harvard George Serafeim',
                "url": 'https://www.youtube.com/embed/BH5mjvQKezc',
                //https://youtu.be/BH5mjvQKezc
              },
              {
                "name": 'Novartis Matchaba Patrice',
                "url": 'https://www.youtube.com/embed/MMo4Uru2rsk',
                //https://youtu.be/MMo4Uru2rsk
              },
              {
                "name": 'Novartis Shannon Klinger',
                "url": 'https://www.youtube.com/embed/VhS1keIhfos',
                //https://youtu.be/VhS1keIhfos
              },
              {
                "name": 'PwC UK Tom Beagent',
                "url": 'https://www.youtube.com/embed/n_mz7frBO5w',
                //https://youtu.be/n_mz7frBO5w
              },
              {
                "name": 'UNGC Sanda Ojiambo',
                "url": 'https://www.youtube.com/embed/8vY4afUkD1c',
                //https://youtu.be/8vY4afUkD1c
              },
              {
                "name": 'Mitsubishi Chemical',
                "url": 'https://www.youtube.com/embed/_-nw3rmrGi4',
                //https://youtu.be/_-nw3rmrGi4
              },
            ]
          },
          {
            yaw: degToRad(86.57),
            pitch: degToRad(-20.47),
            "width": 700,
            "height": 394,
            "perspective": 1250,
            "rotation": {
              "x": 15,
              "y": 0,
              "z": 0,
            },
            'videosData':[
              {
                "name": 'VBA Introduction',
                "url": 'https://www.youtube.com/embed/BcDhiED-vHM',
              },
            ]
          }
        ],
        pdfHotspots: [
          {
            "yaw": degToRad(-58),
            "pitch": degToRad(-9.5),
            //perspective_distance SET to null if you dont want
            "perspective": 700,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '',
            "target": 'demo.pdf'
          },
        ]*/
      },
      {
        id: "ext01",
        name: "Entrance Outdoor",
        initialViewParameters: {
          yaw: degToRad(19.96),
          pitch: degToRad(0.67),
          fov: degToRad(85),
        },
        linkHotspots: [
          {
            yaw: degToRad(14.48),
            pitch: degToRad(-1.46),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "enter_1",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-112.24),
                pitch: degToRad(-2.76),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
          {
            yaw: degToRad(-170.26),
            pitch: degToRad(-1.87),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "ext00",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(19.96),
                pitch: degToRad(0.67),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
        ],
      },
      {
        id: "enter_1",
        name: "Lobby",
        initialViewParameters: {
          yaw: degToRad(-42),
          pitch: degToRad(0),
          fov: degToRad(85),
        },
        linkHotspots: [
          {
            yaw: degToRad(-42.26),
            pitch: degToRad(0.00),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "hall_1_1",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(165.49),
                pitch: degToRad(0.43),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
          {
            yaw: degToRad(-147.75),
            pitch: degToRad(2.58),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "downstair04",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-30.13),
                pitch: degToRad(0),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
          {
            yaw: degToRad(18.33),
            pitch: degToRad(0.27),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "ext01",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-173.61),
                pitch: degToRad(-1.54),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
        ],
      },
      {
        id: "hall_1_1",
        name: "Awareness and Context",
        initialViewParameters: {
          yaw: degToRad(161),
          pitch: degToRad(0),
          fov: degToRad(85),
        },
        linkHotspots: [
          {
            yaw: degToRad(113.18),
            pitch: degToRad(2.98),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "enter_1",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-112.24),
                pitch: degToRad(-2.76),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
          {
            yaw: degToRad(-71.86),
            pitch: degToRad(0.44),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "hall_1_3",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-112.24),
                pitch: degToRad(-2.76),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },

        ],
        pdfHotspots: [
          {
            yaw: degToRad(129.07),
            pitch: degToRad(-8.74),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '01/',
            "target": '1.1_Building trust through inclusive ESG management _Center for Corporate Reporting.pdf'
          },
          {
            yaw: degToRad(138.83),
            pitch: degToRad(-9.45),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '01/',
            "target": '1.2_ESG impact game.pdf'
          },
          {
            yaw: degToRad(150.92),
            pitch: degToRad(-6.94),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '01/',
            "target": '1.17_Impact - Reshaping Capitalism to Drive Real Change.pdf'
          },
          {
            yaw: degToRad(169.04),
            pitch: degToRad(-10.13),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '01/',
            "target": '1.11_GSI_ReviewSummit2021_Summit Brochure 2021.pdf'
          },
          {
            yaw: degToRad(-14.74),
            pitch: degToRad(11.11),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '01/',
            "target": '1.14_MA and ERM.pdf'
          },
          {
            yaw: degToRad(20.81),
            pitch: degToRad(-9.83),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '01/',
            "target": '1.15_Transforming Business Practice_CCR_Reporting_Times_16_CH_27.pdf'
          },
          {
            yaw: degToRad(20.99),
            pitch: degToRad(-3.26),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '01/',
            "target": '1.16_The Great Alignment fuelled by Impact.pdf'
          },
          {
            yaw: degToRad(21.04),
            pitch: degToRad(10.71),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '01/',
            "target": '1.21_Redefining materiality_DC_DW.pdf'
          },
          {
            yaw: degToRad(38.89),
            pitch: degToRad(-10.03),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '01/',
            "target": '1.20_The coming age of impact.pdf'
          },
          {
            yaw: degToRad(38.96),
            pitch: degToRad(10.45),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '01/',
            "target": '1.22_Regeneration - Paul Hawken.pdf'
          },
          {
            yaw: degToRad(59.18),
            pitch: degToRad(15.02),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '01/',
            "target": '1.23_novartis-in-society-report-2020 (3).pdf'
          },
        ],
        iframeVideosHotspots:[
          {
            yaw: degToRad(-118.67),
            pitch: degToRad(-15),
            "width": 700*2,
            "height": 394*2,
            "perspective": 1800,
            "rotation": {
              "x": 15,
              "y": 0,
              "z": 0,
            },
            'videosData':[
              {
                "name": 'SEE Impact Valuation Clip: Intro',
                "url": 'https://player.vimeo.com/video/632834436?h=fddad42a23',
                // 1_3
              },
              {
                "name": 'SEE Impact Valuation Clip: Impact of Products',
                "url": 'https://player.vimeo.com/video/632834578?h=08ad7407e0',
                // 1_4
              },
              {
                "name": 'SEE Impact Valuation Clip: GDP Contribution',
                "url": 'https://player.vimeo.com/video/632834681?h=2c3982a5d1',
                // 1_5
              },
              {
                "name": 'SEE Impact Valuation Clip: Living Wages',
                "url": 'https://player.vimeo.com/video/632834835?h=03790581c5',
                // 1_6
              },
              {
                "name": 'SEE Impact Valuation Clip: Employment',
                "url": 'https://player.vimeo.com/video/632834996?h=266542d77a',
                // 1_7
              },
              {
                "name": 'SEE Impact Valuation Clip: Environment',
                "url": 'https://player.vimeo.com/video/632835119?h=d56c8adc3d',
                // 1_8
              },
              {
                "name": 'Embracing Double Materiality',
                "url": 'https://player.vimeo.com/video/632835277?h=3f729a0ff2',
                // 1_9
              },
              {
                "name": 'Global Solution Summit 2021: Summary video',
                "url": 'https://player.vimeo.com/video/580742618?h=69751ae534',
                // 1_10
              },
              {
                "name": 'Impact Valuation and Materiality',
                "url": 'https://www.youtube.com/embed/UVeJ891IYyk',
                // 1_12
              },
              {
                "name": 'Novartis Materiality Assessment',
                "url": 'https://player.vimeo.com/video/632835339?h=298475ddfd',
                // 1_13
              },
              {
                "name": 'Global Solutions Panel: Novartis Health and Wealth ',
                "url": 'https://player.vimeo.com/video/566648270?h=5abe753251',
                // 1_18 https://vimeo.com/566648270/5abe753251
              },
              {
                "name": 'Global Solutions Panel: VBA How companies can measure social and environmental progress with Paul Penepent',
                "url": 'https://player.vimeo.com/video/564276565?h=7a66136384',
                // 1_19 https://vimeo.com/564276565/7a66136384
              },
              {
                "name": '(Audio Podcast) ESG from a Corporate Point of View',
                "url": 'https://player.vimeo.com/video/642387588?h=eae1a520f4',
                // 1_25
              },
            ]
          },
        ],
      },
      {
        id: "hall_1_3",
        name: "Capabilities",
        initialViewParameters: {
          yaw: degToRad(130),
          pitch: degToRad(0),
          fov: degToRad(85),
        },
        linkHotspots: [
          {
            yaw: degToRad(30.54),
            pitch: degToRad(2.23),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "hall_1_1",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(74.03),
                pitch: degToRad(-0.15),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },

        ],
        pdfHotspots: [
          {
            yaw: degToRad(77.12),
            pitch: degToRad(15.6),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '03/',
            "target": '3.1_AIMD The Novartis Automated Issue Monitoring and Detecting Tool.pdf'
          },
          {
            yaw: degToRad(87.46),
            pitch: degToRad(17.18),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '03/',
            "target": '3.2_Novartis_SEE impact valuation forecast approach_2021.pdf'
          },
          {
            yaw: degToRad(106.73),
            pitch: degToRad(18.28),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '03/',
            "target": '3.3_MA Infographics.pdf'
          },
          {
            yaw: degToRad(128.18),
            pitch: degToRad(-14.05),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '03/',
            "target": '3.4_Total Novartis 2019 SEE Impact Valuation Results.pdf'
          },
          {
            yaw: degToRad(128.17),
            pitch: degToRad(-0.36),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '03/',
            "target": '3.5_GDD ES Poster_CCI_Dec20.pdf'
          },
          {
            yaw: degToRad(-153.35),
            pitch: degToRad(-9.24),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '03/',
            "target": '3.6_WifOR_Scenario Analysis_Project Paper_Novartis.pdf'
          },
          {
            yaw: degToRad(-119.11),
            pitch: degToRad(-15.02),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '03/',
            "target": '3.7_NTO Our Path Towards Green Logistics_16 July_External use.pdf'
          },
          {
            yaw: degToRad(-118.38),
            pitch: degToRad(19.68),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '03/',
            "target": '3.8_Clinical trial diversity US campaign.pdf'
          },
          {
            yaw: degToRad(-69.43),
            pitch: degToRad(16.98),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '03/',
            "target": '3.9_SROI of forestry.pdf'
          },
          {
            yaw: degToRad(-52.79),
            pitch: degToRad(17.88),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '03/',
            "target": '3.10_GDD case studies for CCI Virtual Exhibition.pdf'
          },
          {
            yaw: degToRad(-16.63),
            pitch: degToRad(-12.88),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '03/',
            "target": '3.12_NTO_Sustainability in Packaging document.pdf'
          },
          {
            yaw: degToRad(-16.33),
            pitch: degToRad(20.19),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '03/',
            "target": '3.13_NVS Environmental Sustainability Strategy Overview.pdf'
          },
        ],
        iframeVideosHotspots:[
          {
            yaw: degToRad(174.12),
            pitch: degToRad(1.81),
            "width": 700*1.5,
            "height": 394*1.5,
            "perspective": 1400,
            "rotation": {
              "x": 0,
              "y": -12,
              "z": 0.5,
            },
            'videosData':[
              {
                "name": 'ES eLearning ',
                "url": 'https://player.vimeo.com/video/634056732?h=4fa4862893',
                // 3_11
              },
            ]
          },
        ],
      },
      {
        id: "back_1_1",
        name: "Standards - Sector A",
        initialViewParameters: {
          yaw: degToRad(141.80),
          pitch: degToRad(0),
          fov: degToRad(85),
        },
        linkHotspots: [
          {
            yaw: degToRad(85.93),
            pitch: degToRad(0.46),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "downstair06bar",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-107.83),
                pitch: degToRad(0),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
          {
            yaw: degToRad(19.84),
            pitch: degToRad(1.39),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "back_1_2",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-141.69),
                pitch: degToRad(1.18),
                fov: degToRad(65),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
          {
            yaw: degToRad(-117.76),
            pitch: degToRad(-1.01),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "otherbatiment00",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-141.69),
                pitch: degToRad(1.18),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
        ],
        pdfHotspots: [
          {
            yaw: degToRad(111.18),
            pitch: degToRad(12.93),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '04/',
            "target": '4.1_Health is Wealth_Patrice.pdf'
          },
          {
            yaw: degToRad(121.49),
            pitch: degToRad(14.08),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '04/',
            "target": '4.02_Intro Capitals Coalition Sept 2020.pdf'
          },
          {
            yaw: degToRad(134.00),
            pitch: degToRad(14.86),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '04/',
            "target": '4.3_Statement of Intent, SASBGRI alignment.pdf'
          },
          {
            yaw: degToRad(153.16),
            pitch: degToRad(14.82),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '04/',
            "target": '4.4_VBA Intro video.pdf'
          },
          {
            yaw: degToRad(166.52),
            pitch: degToRad(16.24),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '04/',
            "target": '4.5_WEF_IBC_Measuring_Stakeholder_Capitalism_Report_2020.pdf'
          },
          {
            yaw: degToRad(-178.35),
            pitch: degToRad(16.42),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '04/',
            "target": '4.6_VBA Impact Statement_InputOutput Modelling.pdf'
          },
          {
            yaw: degToRad(-89.15),
            pitch: degToRad(19.81),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '04/',
            "target": '4.8_20210210_VBA Impact Statement_GeneralPaper (9).pdf'
          },
          {
            yaw: degToRad(-52.46),
            pitch: degToRad(23.00),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '04/',
            "target": '4.9_20210302_VBA Method_paper_Environmentals (1).pdf'
          },
        ],

      },
      {
        id: "back_1_2",
        name: "Standards - Sector B",
        initialViewParameters: {
          yaw: degToRad(-10),
          pitch: degToRad(0),
          fov: degToRad(85),
        },
        linkHotspots: [
          {
            yaw: degToRad(-65.45),
            pitch: degToRad(0.63),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "back_1_1",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(114.84),
                pitch: degToRad(0.97),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
          {
            yaw: degToRad(146.94),
            pitch: degToRad(0.56),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "back_1_3",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-101.13),
                pitch: degToRad(-0.60),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
        ],
        pdfHotspots: [
          {
            yaw: degToRad(-166.00),
            pitch: degToRad(7.97),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '04/',
            "target": '4.12_InternationalIntegratedReportingFramework.pdf'
          },
          {
            yaw: degToRad(-137.26),
            pitch: degToRad(8.63),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '04/',
            "target": '4.10_20210316_VBA Impact Statement_Socioeconomic_Publication (3).pdf'
          },
          {
            yaw: degToRad(-124.34),
            pitch: degToRad(8.4),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '04/',
            "target": '4.11_20210511_VBA_1stPILOT STUDY.pdf'
          },
        ],
      },
      {
        id: "back_1_3",
        name: "Standards - Sector C",
        initialViewParameters: {
          yaw: degToRad(-10),
          pitch: degToRad(0),
          fov: degToRad(85),
        },
        linkHotspots: [
          {
            yaw: degToRad(-28.30),
            pitch: degToRad(0.25),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "back_1_2",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-141.24),
                pitch: degToRad(0.75),
                fov: degToRad(65),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
        ],
        pdfHotspots: [
          {
            yaw: degToRad(-92.64),
            pitch: degToRad(25.07),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '04/',
            "target": '4.13_Joint-Statement-on-Value-Accounting-2.pdf'
          },
          {
            yaw: degToRad(-130.96),
            pitch: degToRad(-14.32),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '04/',
            "target": '4.14_WEF_VBA_Joint_Statement.pdf'
          },
          {
            yaw: degToRad(-129.66),
            pitch: degToRad(21.38),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '04/',
            "target": '4.7_VBA_Joint Statement Rethinking Capital VBA FINAL - Jointly Signed.pdf'
          },
        ],
      },
      {
        id: "auditorium02",
        name: "Summit Webcast",
        initialViewParameters: {
          yaw: degToRad(-60.5),
          pitch: degToRad(0),
          fov: degToRad(85),
        },
        linkHotspots: [
          {
            yaw: degToRad(-0.77),
            pitch: degToRad(4.78),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "downstair05",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(54.16),
                pitch: degToRad(5),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
          {
            yaw: degToRad(-119.21),
            pitch: degToRad(3.23),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "enter_1",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-49.09),
                pitch: degToRad(1.80),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
        ],
        externalLinkHotspots: [
          {
            yaw: degToRad(-56.12),
            pitch: degToRad(-5.5),
            //perspective_distance SET to null if you dont want
            perspective: 600,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            url: "https://novartis.webcasts.com/starthere.jsp?ei=1512650&tp_key=14695dd100",
            content: "Morning Session"
          },
          {
            yaw: degToRad(-42.25),
            pitch: degToRad(-5.5),
            //perspective_distance SET to null if you dont want
            perspective: 600,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            url: "https://novartis.webcasts.com/starthere.jsp?ei=1512657&tp_key=b79447f73e",
            content: "Evening Session"
          },
        ]
      },
      {
        id: "downstair00",
        name: "Practitioners Corner - Sector D",
        initialViewParameters: {
          yaw: degToRad(-10),
          pitch: degToRad(0),
          fov: degToRad(85),
        },
        linkHotspots: [
          {
            yaw: degToRad(40.10),
            pitch: degToRad(2.00),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "downstair03",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(109.26),
                pitch: degToRad(3.19),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
          {
            yaw: degToRad(-95.60),
            pitch: degToRad(1.85),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "downstair01",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-7.54),
                pitch: degToRad(2.47),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
        ],
        pdfHotspots: [
          {
            yaw: degToRad(58.71),
            pitch: degToRad(14.10),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.26_Greece Press release_SEE 2020.pdf'
          },
          {
            yaw: degToRad(81.26),
            pitch: degToRad(17.62),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.30_novartis-in-switzerland-2021.pdf'
          },
          {
            yaw: degToRad(110.44),
            pitch: degToRad(18.73),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.31_Novartis-SEE_Project-Media-Report27072021.pdf'
          },
          {
            yaw: degToRad(176.11),
            pitch: degToRad(25.25),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.33_SICKLE CELL DISEASE OVERVIEW - SAUDI ARABIA V2.0.pdf'
          },
          {
            yaw: degToRad(-165.31),
            pitch: degToRad(24.34),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.34_Novatis Role 2030 vision-Apr 2021.pdf'
          },
          {
            yaw: degToRad(-50.62),
            pitch: degToRad(-18.15),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.35_Social Impact of Adakveo in India 2021 to 2025_Final.pdf'
          },
        ],
        imgHotspots:[
          {
            yaw: degToRad(-142.74),
            pitch: degToRad(5.12),
            "perspective": 3500,
            "rotation": {
              "x": -5,
              "y": 8,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.36_210408_Novartis Impact on the UK Economy animated graphic_FUSE 1.0.gif'
          }
        ]
      },
      {
        id: "downstair01",
        name: "Practitioners Corner - Sector E",
        initialViewParameters: {
          yaw: degToRad(-10),
          pitch: degToRad(0),
          fov: degToRad(85),
        },
        linkHotspots: [
          {
            yaw: degToRad(-97.51),
            pitch: degToRad(0.60),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "downstair00",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(60.95),
                pitch: degToRad(1.69),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
          {
            yaw: degToRad(120.23),
            pitch: degToRad(1.52),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "downstair02",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(30.35),
                pitch: degToRad(0),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
        ],
        pdfHotspots: [
          {
            yaw: degToRad(-59.38),
            pitch: degToRad(14.74),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.37_Factsheet_Nachhaltigkeit_Juli 2021_final.pdf'
          },
          {
            yaw: degToRad(-3.79),
            pitch: degToRad(20.94),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.38_Novartis Group SEE 2020-Baltics_Estonia.pdf'
          },
          {
            yaw: degToRad(46.22),
            pitch: degToRad(21.72),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.41_One Pager - Novartis Mexico ENG version.pdf'
          },
          {
            yaw: degToRad(67.89),
            pitch: degToRad(17.70),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.39_The Economic, Social and Innovation Value Novartis Brings to the Netherlands - 02.08.2021.pdf'
          },
          {
            yaw: degToRad(85.52),
            pitch: degToRad(16.80),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.42_WifOR (2021) Are medicines more GHG intensive than cars.pdf'
          },
          {
            yaw: degToRad(98.24),
            pitch: degToRad(14.82),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.43_Infographic - Novartis approach to lower-income populations (approved for internal and external use).pdf'
          },
        ],
      },
      {
        id: "downstair02",
        name: "Practitioners Corner - Sector F",
        initialViewParameters: {
          yaw: degToRad(-10),
          pitch: degToRad(0),
          fov: degToRad(85),
        },
        linkHotspots: [
          {
            yaw: degToRad(-77.80),
            pitch: degToRad(1.51),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "downstair01",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-60.5),
                pitch: degToRad(1),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
          {
            yaw: degToRad(138.45),
            pitch: degToRad(2.13),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "downstair04",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-95.92),
                pitch: degToRad(0.31),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
        ],
        pdfHotspots: [
          {
            yaw: degToRad(-56.75),
            pitch: degToRad(15.41),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.44_Narrative Toolkit - transforming health in lower-income populations (approved for internal and external use).pdf'
          },
          {
            yaw: degToRad(1.62),
            pitch: degToRad(19.08),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.49_Nachhaltigkeitsbericht 2021für Novartis Österreich.pdf'
          },
          {
            yaw: degToRad(25.93),
            pitch: degToRad(20.87),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.50_CSR-Broschuere-BF-final.pdf'
          },
          {
            yaw: degToRad(50.26),
            pitch: degToRad(19.40),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.51_Novartis_corporate_infographic_USD-05.pdf'
          },
          {
            yaw: degToRad(146.86),
            pitch: degToRad(11.43),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.52_Novartis Group SEE 2020-Baltics_Latvia.pdf'
          },
          {
            yaw: degToRad(-147.50),
            pitch: degToRad(15.35),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.53_Novartis Group SEE 2020-Baltics_Lithuania.pdf'
          },
          {
            yaw: degToRad(69.41),
            pitch: degToRad(23.99),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.54_200619_HER_LatAm_CaseStudy.pdf'
          },
        ],
      },
      {
        id: "downstair03",
        name: "Practitioners Corner - Sector C",
        initialViewParameters: {
          yaw: degToRad(-10),
          pitch: degToRad(0),
          fov: degToRad(85),
        },
        linkHotspots: [
          {
            yaw: degToRad(-130.07),
            pitch: degToRad(2.91),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "downstair00",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(105.32),
                pitch: degToRad(-1.21),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
          {
            yaw: degToRad(76.62),
            pitch: degToRad(0.33),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "downstair05",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(150.08),
                pitch: degToRad(1.43),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
          {
            yaw: degToRad(40.71),
            pitch: degToRad(0),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "downstair04",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-151.63),
                pitch: degToRad(1.31),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
        ],
        pdfHotspots: [
          {
            yaw: degToRad(93.67),
            pitch: degToRad(18.44),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.14_Studio_Ambrosetti_Novartis_Nov2018.pdf'
          },
          {
            yaw: degToRad(127.28),
            pitch: degToRad(21.61),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.15_Greece Infographic Final_EN.pdf'
          },
          {
            yaw: degToRad(161.35),
            pitch: degToRad(19.87),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.16_Bangladesh Reimagining our Impact 2019.pdf'
          },
          {
            yaw: degToRad(-119.02),
            pitch: degToRad(11.30),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.17_Novartis SEE Infographic_Thailand_2020 (2).pdf'
          },
          {
            yaw: degToRad(-107.49),
            pitch: degToRad(12.29),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.18_Novartis SEE Impact Results Malaysia 2020 (2).pdf'
          },
          {
            yaw: degToRad(-94.89),
            pitch: degToRad(12.74),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.20_Novartis SEE Infographic_2021_Vietnam_Final.pdf'
          },
          {
            yaw: degToRad(-74.88),
            pitch: degToRad(13.12),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.21_2020 TPRM success stories.pdf'
          },
          {
            yaw: degToRad(-29.41),
            pitch: degToRad(13.20),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.23_Novartis PH Infographics USD FINAL Oct 6.pdf'
          },
          {
            yaw: degToRad(-18.84),
            pitch: degToRad(-13),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.24_Novartis FES Infographic_Pakistan v8.pdf'
          },
          {
            yaw: degToRad(-18.84),
            pitch: degToRad(13.81),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.25_ValueStory_Novartis_AUG_SK.pdf'
          },
        ]
      },
      {
        id: "downstair04",
        name: "Practitioners Corner - Sector A",
        initialViewParameters: {
          yaw: degToRad(-125.47),
          pitch: degToRad(0),
          fov: degToRad(85),
        },
        linkHotspots: [
          {
            yaw: degToRad(166.13),
            pitch: degToRad(-0.02),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "enter_1",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-30.13),
                pitch: degToRad(0),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
          {
            yaw: degToRad(35.09),
            pitch: degToRad(2.75),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "downstair03",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-120.26),
                pitch: degToRad(0.92),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
          {
            yaw: degToRad(-16.23),
            pitch: degToRad(2.13),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "downstair05",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-120.26),
                pitch: degToRad(0.92),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
          {
            yaw: degToRad(72.97),
            pitch: degToRad(0.03),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "uppstare00",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(48.83),
                pitch: degToRad(2.46),
                fov: degToRad(95),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
        ],
        pdfHotspots: [
          {
            yaw: degToRad(-163.90),
            pitch: degToRad(-8.78),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.1_200924_WifOR_HER_Turkey_ManagementSummary_E.pdf'
          },
          {
            yaw: degToRad(-138.81),
            pitch: degToRad(-12.82),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.2_NVS-Ökonomischer_Fussabdruck-Österreich 2020.pdf'
          },
          {
            yaw: degToRad(-71.76),
            pitch: degToRad(-14.26),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.3_Case study__ Breezhaler® Carbon Footprint.pdf'
          },
          {
            yaw: degToRad(-51.76),
            pitch: degToRad(-12.69),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.4_Our Economic and Employment Impact in China_CN2019.pdf'
          },
          {
            yaw: degToRad(-37.80),
            pitch: degToRad(-10.62),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.5_Egypt_FES impact valuation booklet.pdf'
          },
        ]
      },
      {
        id: "downstair05",
        name: "Practitioners Corner - Sector B",
        initialViewParameters: {
          yaw: degToRad(-10),
          pitch: degToRad(0),
          fov: degToRad(85),
        },
        linkHotspots: [
          {
            yaw: degToRad(-147.74),
            pitch: degToRad(0),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "downstair06bar",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-107),
                pitch: degToRad(0),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
          {
            yaw: degToRad(-178.5),
            pitch: degToRad(3.69),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "auditorium02",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-60.5),
                pitch: degToRad(1),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
          {
            yaw: degToRad(-46.19),
            pitch: degToRad(1.29),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "downstair03",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-120.26),
                pitch: degToRad(0.92),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
          {
            yaw: degToRad(33.61),
            pitch: degToRad(0.73),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "downstair04",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-164.16),
                pitch: degToRad(1.41),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
        ],
        pdfHotspots: [
          {
            yaw: degToRad(51.34),
            pitch: degToRad(14.39),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.6_Infographic The Novartis Impact on the UK Economy 2020 update_FINAL.pdf'
          },
          {
            yaw: degToRad(70.66),
            pitch: degToRad(16.64),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.7_The Economic, Social and Innovation Value Novartis Brings to the Netherlands - 02.08.2021.pdf'
          },
          {
            yaw: degToRad(104.20),
            pitch: degToRad(18.67),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.9_Novartis Italia_SEE 2020_Leaflet.pdf'
          },
          {
            yaw: degToRad(-125.34),
            pitch: degToRad(19.4),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.11_Turkey novartis-see-brochure-v4.pdf'
          },
          {
            yaw: degToRad(-107.38),
            pitch: degToRad(20.10),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.12_Portugal Impact Valuation Overview.pdf'
          },
          {
            yaw: degToRad(-89.32),
            pitch: degToRad(18.53),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '05/',
            "target": '5.13_Protectors of Human Health, Safety and Environment.pdf'
          },
        ]
      },
      {
        id: "otherbatiment00",
        name: "External trends - Sector A",
        initialViewParameters: {
          yaw: degToRad(27.17),
          pitch: degToRad(7.24),
          fov: degToRad(85),
        },
        linkHotspots: [
          {
            yaw: degToRad(-47.56),
            pitch: degToRad(1.09),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "back_1_1",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(90.50),
                pitch: degToRad(1.42),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
          {
            yaw: degToRad(118.27),
            pitch: degToRad(1.11),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "otherbatiment01",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(34.07),
                pitch: degToRad(0.78),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
        ],
        pdfHotspots: [
          {
            yaw: degToRad(-7.45),
            pitch: degToRad(19.90),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.1_Accounting for Organizational Employment Impact.pdf'
          },
          {
            yaw: degToRad(3.53),
            pitch: degToRad(22.53),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.2_Framework for Product Impact-Weighted Accounts_Serafeim.pdf'
          },
          {
            yaw: degToRad(17.67),
            pitch: degToRad(24.65),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.3_Hybrid-Metrics-Connecting-Shared-Value-to-Shareholder-Value_September-4-2020.pdf'
          },
          {
            yaw: degToRad(33.71),
            pitch: degToRad(25.36),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.4_Impact Weighted Accounts Report 2019.pdf'
          },
          {
            yaw: degToRad(49.64),
            pitch: degToRad(24.46),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.5_Strategic Sustainability_ G Serafeim and I Ioannou.pdf'
          },
          {
            yaw: degToRad(5.60),
            pitch: degToRad(-5.78),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.6_The_British Academy- future-of-the-corporation-principles-purposeful-business.pdf'
          },
          {
            yaw: degToRad(15.67),
            pitch: degToRad(-6.10),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.7_The Investor Revolution.pdf'
          },
          {
            yaw: degToRad(28.31),
            pitch: degToRad(-6.42),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.8_WEF_Dashboard_for_a_New_Economy_2020.pdf'
          },
          {
            yaw: degToRad(40.04),
            pitch: degToRad(-6.38),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.9_Social Impact Efforts that Create Real Value_Serageim.pdf'
          },
          {
            yaw: degToRad(-112.85),
            pitch: degToRad(16.51),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.10_WBCSD_CEO_Guide_to_Human_Rights.pdf'
          },
          {
            yaw: degToRad(-83.17),
            pitch: degToRad(15.04),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.11_TEEB for Agriculture and Food operational Guidelines for Business.pdf'
          },
          {
            yaw: degToRad(166.99),
            pitch: degToRad(17.24),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.12_Earth Overshoot Day - Final.pdf'
          },
          {
            yaw: degToRad(-167.93),
            pitch: degToRad(21.63),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.13_The importance of resource security for poverty eradication.pdf'
          },
          {
            yaw: degToRad(-153.33),
            pitch: degToRad(22.33),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.16_2021-08-30 GIST\'s Impact Valuation Framework and Methodology.pdf'
          },
          {
            yaw: degToRad(-176.08),
            pitch: degToRad(-6.24),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.17_Sustainability_Management_by_SAP_Overview.pdf'
          },
        ]
      },
      {
        id: "otherbatiment01",
        name: "External trends - Sector B",
        initialViewParameters: {
          yaw: degToRad(-10),
          pitch: degToRad(0),
          fov: degToRad(85),
        },
        linkHotspots: [
          /*{
            yaw: degToRad(72.41),
            pitch: degToRad(1.08),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "ext00",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-10.40),
                pitch: degToRad(-14.56),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },*/
          {
            yaw: degToRad(-105.67),
            pitch: degToRad(0.68),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "otherbatiment00",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-95.92),
                pitch: degToRad(0.31),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
        ],
        pdfHotspots: [
          {
            yaw: degToRad(-50.38),
            pitch: degToRad(12.91),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.19_Lightrock_Novartis_VirtualExhibition_Nov21_healthcare.pdf'
          },
          {
            yaw: degToRad(-70.69),
            pitch: degToRad(11.30),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.18_Lightrock_Novartis_VirtualExhibition_Nov21_overview.pdf'
          },
          {
            yaw: degToRad(99.15),
            pitch: degToRad(19.76),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.20_Improving nature\'s visibility in financial accounting.pdf'
          },
          {
            yaw: degToRad(110.30),
            pitch: degToRad(21.45),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.21_ Then Net Positive Manifesto_Paul Polman n Andrew Winston.pdf'
          },
          {
            yaw: degToRad(124.69),
            pitch: degToRad(22.46),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.22_Our-Value-Report_2020-21-.pdf'
          },
          {
            yaw: degToRad(137.82),
            pitch: degToRad(22.31),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.23_The S in ESG is not silent.pdf'
          },
          {
            yaw: degToRad(150.22),
            pitch: degToRad(21.00),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.24_Integrating Frameworks for Multi-Capital Accounting, Reporting and Valuation.pdf'
          },
          {
            yaw: degToRad(119.95),
            pitch: degToRad(-7.11),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.25_The Difference Between Purpose and Sustainability (aka ESG).pdf'
          },
          {
            yaw: degToRad(-3.45),
            pitch: degToRad(-7.97),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.26_Accounting for Workforce Impact at Scale_Poster.pdf'
          },
          {
            yaw: degToRad(-22.45),
            pitch: degToRad(17.83),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.27_Call for Emergency Action.pdf'
          },
          {
            yaw: degToRad(-9.92),
            pitch: degToRad(20.10),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.28_Heroic Accounting.pdf'
          },
          {
            yaw: degToRad(4.62),
            pitch: degToRad(21.31),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.29_The Trillion-Dollar Fantasy  Institutional Investor.pdf'
          },
          {
            yaw: degToRad(20.29),
            pitch: degToRad(21.40),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.32_Evonik Poster for Novartis Co-creating Impact Summit 2021.pdf'
          },
          {
            yaw: degToRad(-178.59),
            pitch: degToRad(19.04),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.33_social and human capital protocol.pdf'
          },
          {
            yaw: degToRad(-167.89),
            pitch: degToRad(18.75),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.36_Natural Capital Protocol.pdf'
          },
          {
            yaw: degToRad(-158.11),
            pitch: degToRad(17.79),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.37_Principles-of-integrated-capitals-assessments_v362.pdf'
          },
          {
            yaw: degToRad(-149.23),
            pitch: degToRad(16.72),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.38_Connecting-Finance-and-Natural-Capital_Supplement-to-the-Natural-Capital-Protocol-1.pdf'
          },
          {
            yaw: degToRad(-174.14),
            pitch: degToRad(-2.26),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.39_Disclosing-capitals-in-financial-statements-FINAL.pdf'
          },
          {
            yaw: degToRad(-166.73),
            pitch: degToRad(-2.26),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.40_NCC_ForestProductsSectorGuide_Web.pdf'
          },
          {
            yaw: degToRad(-159.69),
            pitch: degToRad(-2.18),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '06/',
            "target": '6.41_NCC_FoodAndBeverage_WEB_2016-07-12 (1).pdf'
          },
        ],
        iframeVideosHotspots:[
          {
            yaw: degToRad(70),
            pitch: degToRad(-3.18),
            "width": 700*1.5,
            "height": 394*1.5,
            "perspective": 1600,
            "rotation": {
              "x": 3,
              "y": 0,
              "z": 0,
            },
            'videosData':[
              {
                "name": 'WEF environment video',
                "url": 'https://www.youtube.com/embed/o-KyEYa3vuM',
                // 6_15
              },
              {
                "name": 'Lightrock - Introduction Clip by Marc Mose',
                "url": 'https://player.vimeo.com/video/638632998?h=c72fe0b793',
                // 6_30
              },
              {
                "name": 'The Purpose of Capitalism: Lessons from Japan Trailer',
                "url": 'https://www.youtube.com/embed/bAmhv4wB67w',
                // 6_31
              },
              {
                "name": 'Gist: i360x Demo',
                "url": 'https://player.vimeo.com/video/634056526?h=00f5ac9655',
                // 6_35
              },
              {
                "name": 'Gist: GIID introduction video',
                "url": 'https://player.vimeo.com/video/634056715?h=7b71855f21',
                // 6_36
              },
            ]
          },
        ],
      },
      {
        id: "uppstare00",
        name: "Science and Methodology - Sector A",
        initialViewParameters: {
          yaw: degToRad(-96),
          pitch: degToRad(0),
          fov: degToRad(85),
        },
        linkHotspots: [
          {
            yaw: degToRad(-157.61),
            pitch: degToRad(8.08),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "downstair04",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-75.66),
                pitch: degToRad(8.12),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
          {
            yaw: degToRad(161.62),
            pitch: degToRad(-0.44),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "uppstare09",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(54.16),
                pitch: degToRad(7.45),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
        ],
        pdfHotspots: [
          {
            yaw: degToRad(-137.19),
            pitch: degToRad(11.57),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '02/',
            "target": '2.1_2017_ISPOR_Secukinumab_Poster_WifOR_Novartis.pdf'
          },
          {
            yaw: degToRad(-127.71),
            pitch: degToRad(13.45),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '02/',
            "target": '2.2_Case_Study_Novartis_Global_Environmental-Impact_WifOR.pdf'
          },
          {
            yaw: degToRad(-116.02),
            pitch: degToRad(14.95),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '02/',
            "target": '2.3_2018_Novartis_Social-Impact-ZA-and-Kenya_Case-Study_WifOR-4.pdf'
          },
          {
            yaw: degToRad(-89.92),
            pitch: degToRad(17.63),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '02/',
            "target": '2.4_Assessment of Social impact of Pipeline Products.pdf'
          },
          {
            yaw: degToRad(-65.60),
            pitch: degToRad(23.57),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '02/',
            "target": '2.5_CCI_DirectGDPCalculation.pdf'
          },
          {
            yaw: degToRad(-7.38),
            pitch: degToRad(-7.78),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '02/',
            "target": '2.8_IVR_Impact_Valuation_White_Paper.pdf'
          },
          {
            yaw: degToRad(-7.96),
            pitch: degToRad(6.57),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '02/',
            "target": '2.7_Himmler et al. (2019) A case study applying a novel approach to estimate the social impact.pdf'
          },
          {
            yaw: degToRad(-7.97),
            pitch: degToRad(18.49),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '02/',
            "target": '2.6_Cost Effectiveness of Secukinumab Versus Other Biologics and Apremilast in the Treatment of Active Psoriatic.pdf'
          },
          {
            yaw: degToRad(35.04),
            pitch: degToRad(17.07),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '02/',
            "target": '2.9_Novartis-MA-Toolkit latest.pdf'
          },
          {
            yaw: degToRad(85.83),
            pitch: degToRad(-14.27),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '02/',
            "target": '2.10_Novartis_Infographic_M_and_E.pdf'
          },
          {
            yaw: degToRad(112.24),
            pitch: degToRad(-12.52),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '02/',
            "target": '2.11_Measuring n Valuing Social Impact of Wages.pdf'
          },
          {
            yaw: degToRad(88.66),
            pitch: degToRad(-4.50),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '02/',
            "target": '2.12_201104_ISPOR Poster SI Omalizumab_WifOR_after review_final.pdf'
          },
          {
            yaw: degToRad(87.89),
            pitch: degToRad(12.85),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '02/',
            "target": '2.13_Social impact of pediatric applications.pdf'
          },

        ]
      },
      {
        id: "uppstare09",
        name: "Science and Methodology - Sector B",
        initialViewParameters: {
          yaw: degToRad(-10),
          pitch: degToRad(0),
          fov: degToRad(85),
        },
        linkHotspots: [
          {
            yaw: degToRad(-40.75),
            pitch: degToRad(1.54),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "uppstare00",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-75.60),
                pitch: degToRad(5.48),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
          {
            yaw: degToRad(139.38),
            pitch: degToRad(0.57),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "uppstare08",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-59.01),
                pitch: degToRad(3.29),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
        ],
        pdfHotspots: [
          {
            yaw: degToRad(43.07),
            pitch: degToRad(-13.20),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '02/',
            "target": '2.15_04.11.2020_Poster _ Tubeho Neza project.pdf'
          },
          {
            yaw: degToRad(60.51),
            pitch: degToRad(-16.38),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '02/',
            "target": '2.14_VN_HumanRightsValueChains_2021 (9).pdf'
          },
          {
            yaw: degToRad(43.45),
            pitch: degToRad(-1.67),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '02/',
            "target": '2.17_WifOR_Social Impact of Novartis and Sandoz Products final.pdf'
          },
          {
            yaw: degToRad(92.61),
            pitch: degToRad(-13.20),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '02/',
            "target": '2.16_WifOR_Novartis_Case_Study_Global_Economic_Impact.pdf'
          },
          {
            yaw: degToRad(42.53),
            pitch: degToRad(8.52),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '02/',
            "target": '2.18_The Social Impact of Erenumab in prophylactic treatment of patients with migraine in Germany A macroeconomic opencohort approach.pdf'
          },

        ]
      },
      {
        id: "uppstare08",
        name: "Science and Methodology - Sector C",
        initialViewParameters: {
          yaw: degToRad(-10),
          pitch: degToRad(0),
          fov: degToRad(85),
        },
        linkHotspots: [
          {
            yaw: degToRad(-155.15),
            pitch: degToRad(2.39),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "uppstare09",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(54.16),
                pitch: degToRad(7.45),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
        ],
        pdfHotspots: [
          {
            yaw: degToRad(-71.67),
            pitch: degToRad(-14.97),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '02/',
            "target": '2.19_Social Burden of Migraine - EHF Poster.pdf'
          },
/*          {
            yaw: degToRad(-72.06),
            pitch: degToRad(-7.03),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '02/',
            "target": '2.20_GDD case studies for CCI Virtual Exhibition.pdf'
          },*/
          {
            yaw: degToRad(-13.03),
            pitch: degToRad(22.45),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '02/',
            "target": '2.22_Measuring Value - Towards New Metrics and Methods.pdf'
          },
          {
            yaw: degToRad(-42.42),
            pitch: degToRad(18.49),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '02/',
            "target": '2.21_From impact valuation to investing for purpose.pdf'
          },
          {
            yaw: degToRad(27.26),
            pitch: degToRad(21.06),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '02/',
            "target": '2.23_Measuring Purpose - An integrated Framwork.pdf'
          },
          {
            yaw: degToRad(62.38),
            pitch: degToRad(22.46),
            //perspective_distance SET to null if you dont want
            "perspective": null,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            "subfolder": '02/',
            "target": '2.25_Social Impact of Prophylactic Migraine Treatments in Germany A State-Transition and Open Cohort Approach.pdf'
          },

        ]
      },
      {
        id: "downstair06bar",
        name: "Practitioners Corner - Video's Area",
        initialViewParameters: {
          yaw: degToRad(-107.83),
          pitch: degToRad(0),
          fov: degToRad(65),
        },
        linkHotspots: [
          {
            yaw: degToRad(149.85),
            pitch: degToRad(1.70),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "downstair05",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(54.16),
                pitch: degToRad(5),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
          {
            yaw: degToRad(-2.47),
            pitch: degToRad(0.47),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "back_1_1",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-90.81),
                pitch: degToRad(-1.03),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
        ],
        iframeVideosHotspots:[
          {
            yaw: degToRad(-107.83),
            pitch: degToRad(0.30),
            "width": 700*2,
            "height": 394*2,
            "perspective": 1800,
            "rotation": {
              "x": 0,
              "y": 0,
              "z": 0,
            },
            'videosData':[
              {
                "name": 'Italy: Novartis in Italy (SEE Results)',
                "url": 'https://player.vimeo.com/video/634058451?h=7711ad3fd3',
                // 5_8 https://vimeo.com/634058451/7711ad3fd3
              },
              {
                "name": 'Italy: Materiality Assessment',
                "url": 'https://player.vimeo.com/video/634058520?h=c3ec6912f6',
                //5_10 https://vimeo.com/634058520/c3ec6912f6
              },
              {
                "name": 'Greece: 2020 SEE Impact Valuation results',
                "url": 'https://player.vimeo.com/video/634058566?h=d4110799da',
                // 5_27
              },
              {
                "name": 'Spain: La Huella - Build trust with society',
                "url": 'https://www.youtube.com/embed/vbdTeOwC_f0',
                //5_28
              },
              {
                "name": 'Saudi - Sickle Cell forecasting',
                "url": 'https://player.vimeo.com/video/634057135?h=351cadb3b9',
                //5_32
              },
              {
                "name": 'India: recording of Dennis social impact of Adakveo',
                "url": 'https://player.vimeo.com/video/634058896?h=08bcc0ddb5',
                // 5_40
              },
              {
                "name": 'Global Health: Transforming health in lower-income populations',
                "url": 'https://www.youtube.com/embed/Mx-ZuQm1RI8',
                //5_44
              },
              {
                "name": 'Global Health: No one should die of Malaria',
                "url": 'https://player.vimeo.com/video/634059622?h=149e511cb1',
                //5_45
              },
              {
                "name": 'Global Health: World Mosquito Day 2021',
                "url": 'https://player.vimeo.com/video/634059973?h=bea77b1c06',
                //5_46
              },
              {
                "name": 'Global Health: Novartis Malaria Innovation and Access Journey',
                "url": 'https://player.vimeo.com/video/634061576?h=0d0aee1f06',
                //5_47
              },
            ]
          },
        ],
      },
      {
        id: "downstair07elevator",
        name: "Awareness and Context - Video's Area",
        initialViewParameters: {
          yaw: degToRad(-30.13),
          pitch: degToRad(0),
          fov: degToRad(75),
        },
        linkHotspots: [
          {
            yaw: degToRad(60.48),
            pitch: degToRad(3.52),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "downstair04",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(1.89),
                pitch: degToRad(0.93),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
          {
            yaw: degToRad(-121.11),
            pitch: degToRad(1.46),
            //perspective_distance SET to null if you dont want
            perspective: null,
            rotation: {
              x: 0,
              y: 0,
              z: 0,
            },
            target: "enter_1",
            mdi: "mdi-record-circle-outline",
            customClass: "link-hotspot-red",
            destinationViewParameters: {
              camera: {
                yaw: degToRad(-9.57),
                pitch: degToRad(0.63),
                fov: degToRad(75),
              },
              options: {
                transitionDuration: 0,
              },
            },
          },
        ],

      },
    ],
  },
  getters: {},
  mutations: {},
  actions: {},
};

function degToRad(value) {
  return (value * Math.PI) / 180;
}
