<template>
  <div>
    <div id="pano"></div>
    <Navigation v-if="viewer != null" :viewer="viewer"/>
    <popup-video v-if="videoPlayerIsOpen"/>
    <bio-main v-if="userBioIsOpen" />
    <guest-book v-if="guestBookIsOpen"/>
  </div>
</template>

<script>
var Marzipano = require("marzipano");

import sceneTools from "@/services/sceneTools";
import Navigation from '@/components/navigation/Navigation'
import PopupVideo from '@/components/popup/PopupVideo'
import BioMain from "@/components/bios/BioMain";
import GuestBook from "@/components/bios/GuestBook";

import { mapState } from "vuex";
export default {
  name: "MainView",
  components: {
    Navigation,
    PopupVideo,
    BioMain,
    GuestBook
  },
  data() {
    return {
      dataScenes: this.$store.state.scenes.scenes,
      scenes: null,
      urlPrefix: "",
      photosPath: "img/img360/",
      tileMapConfig: [
        {
          tileSize: 256,
          size: 256,
          fallbackOnly: true,
        },
        {
          tileSize: 512,
          size: 512,
        },
        {
          tileSize: 512,
          size: 1024,
        },
        {
          tileSize: 512,
          size: 2048,
        },
        {
          tileSize: 512,
          size: 4096,
        },
      ],
      viewer: null,
    };
  },
  mounted() {
    this.createScene();
    this.$store.state.currentPdf = this.$store.state.pdfDemoFile;
  },
  computed: {
    ...mapState(["nextRoom", "videoPlayerIsOpen", "userBioIsOpen", "guestBookIsOpen"]),
  },
  methods: {
    createScene() {
      let vueData = this;
      const panoElement = document.getElementById("pano");
      let viewer = new Marzipano.Viewer(panoElement);
      this.viewer = viewer;

      // iterate on each scene to create 360 view and adding all content
      let scenes = this.dataScenes.map((current_scene) => {
        let geometry = new Marzipano.CubeGeometry(vueData.tileMapConfig);

        //Get 360 imgs datas for cubemap
        let imgPath = vueData.urlPrefix + vueData.photosPath + current_scene.id;
        let src = Marzipano.ImageUrlSource.fromString(
          imgPath + "/{z}/{f}/{y}/{x}.jpg",
          {
            cubeMapPreviewUrl: imgPath + "/preview.jpg",
          }
        );

        let limiter = Marzipano.RectilinearView.limit.traditional(
          4092,
          degToRad(140)
        );
        let view = new Marzipano.RectilinearView(
          current_scene.initialViewParameters,
          limiter
        );

        let scene = viewer.createScene({
          source: src,
          geometry: geometry,
          view: view,
          pinFirstLevel: true,
        });

        const addHotspotToScene = (element, hotspot) => {
          scene.hotspotContainer().createHotspot(
            element,
            {
              yaw: hotspot.yaw,
              pitch: hotspot.pitch,
            },
            {
              perspective: {
                radius: hotspot.perspective,
                extraTransforms:
                  `rotateZ(${hotspot.rotation.z}deg)` +
                  `rotateY(${hotspot.rotation.y}deg)` +
                  `rotateX(${hotspot.rotation.x}deg)`,
              },
            }
          );
        };

        if (current_scene.linkHotspots) {
          current_scene.linkHotspots.forEach((hotspot) => {
            let element = sceneTools.createNavigationLinkHotspot(hotspot);
            addHotspotToScene(element, hotspot);
          });
        }

        if (current_scene.iframeVideosHotspots) {
          current_scene.iframeVideosHotspots.forEach((hotspot) => {
            let element = sceneTools.createIframeVideosHotspots(hotspot);
            addHotspotToScene(element, hotspot);
          });
        }

        if (current_scene.pdfHotspots) {
          current_scene.pdfHotspots.forEach((hotspot) => {
            let element = sceneTools.createPdfHotspots(hotspot);
            addHotspotToScene(element, hotspot);
          });
        }

        if (current_scene.imgHotspots) {
          current_scene.imgHotspots.forEach((hotspot) => {
            let element = sceneTools.createImgHotspots(hotspot);
            addHotspotToScene(element, hotspot);
          });
        }

        if (current_scene.externalLinkHotspots) {
          current_scene.externalLinkHotspots.forEach((hotspot) => {
            let element = sceneTools.createExternalLinkHotspots(hotspot);
            addHotspotToScene(element, hotspot);
          });
        }

        sceneTools.iframeBugCorrection(viewer);

        return {
          data: current_scene,
          scene: scene,
          view: view,
        };
      });
      this.scenes = scenes;
      this.switchScene(scenes[vueData.$store.state.defaultScene]);
    },
    switchScene(scene, destinationParams = null) {
      //scene.view.setParameters(scene.data.initialViewParameters);
      scene.scene.switchTo();

      if (destinationParams != null) {
        scene.scene.lookTo(destinationParams.camera, destinationParams.options);
      }
      // iframeHack
      document.querySelectorAll("iframe").forEach(iframe => {
        if (iframe.classList.contains('switch-scene-reset-iframe')){
          let bckpSrc = iframe.src;
          iframe.src = "";
          iframe.src = bckpSrc;
        }
      })

      // UPDATE DEV TOOLS
      if (process.env.NODE_ENV !== "production") {
        const vueData = this;
        document.getElementById("pano").addEventListener("click", function (e) {
          let loc = scene.view.screenToCoordinates({
            x: e.clientX,
            y: e.clientY,
          });
          let clickPos = [{}, {}];
          clickPos[0].rad = loc.yaw;
          clickPos[1].rad = loc.pitch;
          clickPos[0].deg = radToDeg(loc.yaw).toFixed(2);
          clickPos[1].deg = radToDeg(loc.pitch).toFixed(2);
          vueData.$store.commit("UPDATE_CLICKPOS", clickPos);
        });
      }

      this.$store.state.currentScene = {
        sceneId: scene.data.id,
        id: scene,
        name: scene.data.name,
      };
    },
  },
  watch: {
    nextRoom(arrayId) {
      if (arrayId !== null) {
        this.switchScene(
          this.scenes[arrayId],
          this.$store.state.nextRoomParameters
        );
        this.$store.state.nextRoom = null;
        this.$store.state.nextRoomParameters = null;
      }
    },
  },
};

function degToRad(value) {
  return (value * Math.PI) / 180;
}

function radToDeg(value) {
  return (value * 180) / Math.PI;
}
</script>

<style lang="scss" scoped>
@import "MainView";
</style>
