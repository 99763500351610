<template>
  <div id="app" tabindex="0" @keydown.esc="pressEsc">
    <devTools v-if="false" />
    <div id="info_on_mouse">INFO ON MOUSE</div>
    <document-viewer v-show="currentPdf" :pdf="currentPdf" />
    <MainView />
  </div>
</template>

<script>
import MainView from "./components/MainView.vue";
import devTools from "@/components/dev/devTools";
import DocumentViewer from "@/components/pdf/DocumentViewer";

var bowser = require("bowser");

export default {
  name: "App",
  components: {
    DocumentViewer,
    MainView,
    devTools,
  },
  computed: {
    currentPdf() {
      return this.$store.state.currentPdf;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    pressEsc() {
      if (this.$store.state.pdfIsOpen) {
        this.$store.state.pdfIsOpen = false;
      }
    },
    init() {
      // Detect desktop or mobile mode.
      if (window.matchMedia) {
        var setMode = function () {
          if (mql.matches) {
            document.body.classList.remove("desktop");
            document.body.classList.add("mobile");
          } else {
            document.body.classList.remove("mobile");
            document.body.classList.add("desktop");
          }
        };
        var mql = matchMedia("(max-width: 500px), (max-height: 500px)");
        setMode();
        mql.addListener(setMode);
      } else {
        document.body.classList.add("desktop");
      }
      // Detect whether we are on a touch device.
      document.body.classList.add("no-touch");
      window.addEventListener("touchstart", function () {
        document.body.classList.remove("no-touch");
        document.body.classList.add("touch");
      });
      // Use tooltip fallback mode on IE < 11.
      if (bowser.msie && parseFloat(bowser.version) < 11) {
        document.body.classList.add("tooltip-fallback");
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  position: relative;
  width: 100%;
  height: 100%;
}
#info_on_mouse {
  background-color: $main-color;
  position: fixed;
  z-index: 5;
  font-size: 22px;
  letter-spacing: 1.1px;
  font-family: Volta-Blk;
  color: white;
  padding: 8px 32px;
  opacity: 0;
  transform: scaleY(0);
  transition: all 0s, opacity 0s, transform 0s;
  border-radius: 5px;
  &.visible{
    opacity: 1;
    transform: scaleY(1);
    transition: all 0s, opacity .4s, transform .2s;
  }
}
</style>
