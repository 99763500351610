import store from "../store/index";

/*offset value base on which location time zone you would like to set
For India offset value +5.5,
New York offset value -4,
London offset value +1*/
let getTime = (timezone = 0) => {
  //let cDate = new Date().toLocaleString("fr-FR", { timeZone: "Europe/London" });
  let d = new Date();
  let utc = d.getTime() + d.getTimezoneOffset() * 60000;
  let cD = new Date(utc + 3600000 * timezone);
  let cdArray = cD.toString().split(" ");

  let dateData = {
    timestamp: Date.now(),
    day: [cdArray[0], cdArray[2]],
    month: cdArray[1],
    year: cdArray[3],
    h: cdArray[4].split(":")[0],
    m: cdArray[4].split(":")[1],
    s: cdArray[4].split(":")[2],
  };
  return dateData;
};

let getTimeByInterval = (timezone = 0, interval = 500) => {
  setTimeout(() => {
    getTimeByInterval(timezone, interval);
    store.state.time = getTime(timezone);
  }, interval);
};

export default {
  getTime,
  getTimeByInterval,
};
