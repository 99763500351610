<template>
<div id="bio-main">
  <div id="bio-main-container">
  <div @click="$store.state.userBioIsOpen = false" class="close-button"> <i class="mdi mdi-close"></i> </div>
    <div class="users">
      <div v-for="user in user_bios">
        <div @click="current_user = user" style="cursor: pointer;" class="photo">
          <img loading="lazy" :src="'img/bios_photos/' + user.image" :alt="user.firstName + '_' + user.lastName">
        </div>
        <div class="content">
          <h2 @click="current_user = user" style="cursor: pointer;">{{ user.firstName }} {{ user.lastName }}</h2>
          <h3 @click="current_user = user" style="cursor: pointer;">{{ user.title }}</h3>
          <p @click="current_user = user" style="cursor: pointer;">{{ user.functionDuringEvent }}</p>
          <a v-if="user.linkedin" :href="user.linkedin" target="_blank"><i class="mdi mdi-linkedin"></i></a>
        </div>
      </div>
    </div>
  </div>
  <bio-detail v-if="current_user != null" :user="current_user" v-on:closeDetail="current_user = null"/>
</div>
</template>

<script>

import BioDetail from "@/components/bios/BioDetail";

export default {
  name: "BioMain",
  components: {
    BioDetail,
  },
  computed:{
    user_bios() {
      let from_store_Array = this.$store.state.bios.bios
      let new_array = from_store_Array.sort( this.compare )
      return new_array
    }
  },
  methods: {
      compare( a, b ) {
        if ( a.firstName < b.firstName ){
          return -1;
        }
        if ( a.firstName > b.firstName ){
          return 1;
        }
        return 0;
      }
  },
  data: function() {
    return {
      current_user: null,
    }
  }
};
</script>

<style lang="scss" scoped>
@import "bio";
</style>